'use strict'

###*
 # @ngdoc object
 # @name mundoLive.controller:MundoLiveCtrl

 # @description

###
class MundoLiveCtrl
  ### @ngInject ###
  constructor:(
    $scope
    $log
    $filter
    $window
    $timeout
    $mdSidenav
    $rootScope
    $stateParams
    $location
    $compile
    mundoConfiguration
    MundoMap
    ReportConfigurationManager
    ReportConfigurationService
    ServiceReportVisualizeManager
    ReportSourceManager
    ReportFilterService
    LiveMapService
    LiveSubscriptionManager
    LiveSubscriptionService
    MundoSocket
    StatusData
    _
    tmhDynamicLocale
    UiHelpers
    hotkeys
    RestUtils
  ) ->

    # Set locale for some reason
    tmhDynamicLocale.set('nl-be')

    @trackingOptions = mundoConfiguration.trackingOptions
    @filterQuery = ""
    @features = {}
    @filteredFeatures = {}
    @statuses = {}
    @tracking =
      none: true
      all: false
      selection: false

    @authenticationSuccessEventName = 'mundo:authentication:success'

    ## -----------------------------
    ## INITIALISATION OF THE PAGE
    ## -----------
    @init = () =>
      # create a map instance
      @map = LiveMapService.getMap 'liveMap'

      @markerLayer = MundoMap.getLayerById @map, 'markers'
      @markerLayer.setStyle (feature) ->
        LiveMapService.getMarkerLayerStyle feature

      @heatmapLayer = MundoMap.getLayerById @map, 'heatmap'
      @heatmapLayer.setRadius 12
      @heatmapLayer.setBlur 25

      @sidebarTab = 0

      # Don't show the selection tab initially
      $rootScope.showSelection = false

      ReportSourceManager.getList().then (reportSources) =>
        angular.forEach reportSources, (source) =>
          if source.code == "MongoUnitStatus"
            @source = source

      LiveSubscriptionService.enable().then (configuration) =>
        @initialLoad configuration

      @trackedUnits = {}

      @selectFeatureOnMapInteraction = new ol.interaction.Select
        condition: ol.events.condition.click
        layers: [@markerLayer]
        style: (feature) ->
          style = feature.get '_cachedStyle'

      @map.addInteraction @selectFeatureOnMapInteraction
      @selectFeatureOnMapInteraction.on 'select', (e) =>
        if e.selected.length > 0
          @selectFeatureOnMap e

      @map.on 'pointerdrag', (e) =>
        @setTracking()

    @selectFeatureOnMap = (e) =>
      layerProps = e.target.getLayer(e.selected[0]).getProperties()
      featureProps = e.selected[0].getProperties()
      oldCount = _.keys(@trackedUnits).length

      # Deselect features for reselect directly
      @selectFeatureOnMapInteraction.getFeatures().clear()

      if layerProps._layerId is "markers"
        features = featureProps.features

        if features.length == 1
          feature = features[0]
          id = features[0].get('_status').unit.id

          if(@trackedUnits[id]?)
            feature._selected = false
            feature.set '_cachedStyle', null
            delete @trackedUnits[id]
          else
            feature._selected = true
            feature.set '_cachedStyle', null
            @trackedUnits[id] = features[0]
            # Switch to selection tab

          $scope.$apply()
          @autoPan()
          @onResize(50)
        else
          LiveMapService.zoomMapToFeatures @map, features

    @focus = (feature) =>
      @setTracking()
      LiveMapService.zoomMapToFeatures @map, { feature } , 0

    @toggleFeatureSelect = (feature) =>
      oldCount = _.keys(@trackedUnits).length
      id = feature.get('_status').unit.id

      if(@trackedUnits[id]?)
        feature._selected = false
        feature.set '_cachedStyle', null
        delete @trackedUnits[id]
      else
        feature._selected = true
        feature.set '_cachedStyle', null
        @trackedUnits[id] = feature
      @autoPan()
      @onResize(50)

    @throttled = _.throttle () ->
      $scope.$apply()
    , 3000

    $rootScope.$on 'navigation::toggle', () =>
      @onResize()

    $rootScope.subscriptionListener = $rootScope.$on MundoSocket.options.subscriptionConsumer.dataEventName,
      (event, data) =>
        # Go and add the status to the map.
        LiveMapService.addStatusToMap data.event, @features, @markerLayer, @statuses, true, @heatmapLayer
        @throttled()
        @autoPan()

    @initialLoad = (configuration, reload = true) =>
      config = configuration
      @errorMessage = null

      params =
        configuration : config
        limit : 500
        bypassFields : true
        visualizer : 'JSON'

      ServiceReportVisualizeManager.getList(params).then(
        (reports) =>
          angular.forEach reports, (status) =>
            LiveMapService.addStatusToMap status, @features, @markerLayer, @statuses, true, @heatmapLayer
          LiveMapService.zoomMapToFeatures @map, @features, 0
          @filteredFeatures = @features
        () ->
          $log.debug "Something went wrong with loading the configuration"
      )

    angular.element($window).bind 'resize', () =>
      @onResize()

    @onResize = (delay = 25) =>
      $timeout () =>
        @map.updateSize()
      , 25

    @selectionTabSelect = () =>
      $rootScope.showSelection = true
      angular.forEach @features, (f) ->
        f.set '_cachedStyle', null

    @allTabSelect = () =>
      $rootScope.showSelection = false
      angular.forEach @features, (f) ->
        f.set '_cachedStyle', null

    @selectFilters = () =>
      LiveSubscriptionService.getConfiguration().then (configuration) =>
        config = configuration
        ReportFilterService.selectFilters(@source, configuration, ['unit.id']).then () =>
          LiveSubscriptionService.disable().then () =>
            LiveSubscriptionService.renewSubscription(config._id).then (cfg) =>
              # Reset all the things and create a new subscription and stuff
              @features = {}
              @statuses = {}
              @trackedUnits = {}
              @filterQuery = ""

              # Get rid of all markers
              @markerLayer.getSource().getSource().clear()
              @heatmapLayer.getSource().clear()

              @initialLoad cfg
              @onResize()


    @setTracking = (active = "none", manual = false) =>
      angular.forEach @tracking, (value, key) =>
        if key != active
          @tracking[key] = false
        else
          if manual
            @tracking[key] = !@tracking[key]

    @autoPan = () =>
      if @tracking.all
        LiveMapService.zoomMapToFeatures @map, @features, 0
      if @tracking.selection
        LiveMapService.zoomMapToFeatures @map, @trackedUnits, 0

    @searchFeatures = () =>
      if not (@filterQuery? and @filterQuery.length)
        @filteredFeatures = @features
        return true

      searchValue = @filterQuery.toLowerCase()

      newFeatures = {}
      angular.forEach @features, (feature, key) ->
        compareValue = feature._label.toLowerCase()
        if(feature.get('_status').unit.dispatchUnit?)
          compareValue += ' ' + feature.get('_status').unit.dispatchUnit.label.toLowerCase()
        if compareValue.indexOf(searchValue) > -1
          newFeatures[key] = feature

      @filteredFeatures = newFeatures


    ## ---------------
    ## HOTKEYS <3
    ## -----------

    hotkeys.add
      combo: 'f a'
      description: 'Follow all units [toggle]'
      callback: () =>
        @setTracking "all", true
        @autoPan()

    hotkeys.add
      combo: 'f s'
      description: 'Follow selection [toggle]'
      callback: () =>
        @setTracking "selection", true
        @autoPan()

    hotkeys.add
      combo: 'f n'
      description: 'Follow nothing'
      callback: () =>
        @setTracking "none", true
        @autoPan()

    hotkeys.add
      combo: 'c s'
      description: 'Clear Selection'
      callback: () =>
        @trackedUnits = {}
        angular.forEach @features, (f) ->
          f._selected = false
          f.set '_cachedStyle', null
        @setTracking "none", true

    hotkeys.add
      combo: 'e f'
      description: 'Edit Filters'
      callback: () =>
        @selectFilters()


    @init()

angular
  .module('mundoLive')
  .controller 'MundoLiveCtrl', MundoLiveCtrl
